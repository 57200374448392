import React from 'react';
import '../styles/components/Header.css';
import '../styles/pages/Help.css';
import { useTranslation } from "react-i18next";

const Help = (props) => {

    const [t, i18n] = useTranslation('common');

    //console.log('LANGUAGE: ' + i18n.language)

    return (

        <div>
            <div className="an-logo-header an-logo-header--beyond-fossils">
                <div className="an-logo-header__container">
                    <div className="an-logo-header__row">
                        <div className="an-logo-header__title an-logo-header__title--no-company">
                            <span className="an-logo-header__title-companyname">UPM Forest</span>
                            <span className="an-logo-header__title-site-name"> Web Admin Tool</span>
                            <span className="an-logo-header__title-site-name"> Help</span>
                        </div>
                        <div className="an-logo-header__center"></div>
                        <div className="an-logo-header__logo">
                            <div className="an-logo-header__logo-wrapper">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-main">
                <div className="help-section help-section-support" style={{}} key="helpsupportsection">
                    <h3>{t('help.supportContactInfo')}</h3>
                    <p className="help-section-sentence">{t('help.sdPleaseContactUs')}</p>
                    <p className="help-section-sentence-small">{t('help.sdEmail')} <strong className="help-section-email">webadmintoolsupport@upm.com</strong></p>
                </div>
                <div className="help-section help-section-documents" style={{}} key="helpdocumenttsection">
                    <h3>{t('help.webAdminToolHelpDocuments')}</h3>
                    <div className="help-section-docs-shadowed" style={{ marginTop: '20px' }}>
                        <p className="help-section-sentence" style={{ marginBottom: '10px' }}>{t('help.shortUserGuides')}</p>
                        <a className="help-section-link" target="_blank" href="https://upmextb2cassetsqa.blob.core.windows.net/watuserguides/Web Admin Tool - short manual.pdf" >{t('help.watShortManual') + ' (EN)'}</a><br></br>
                        <a className="help-section-link" target="_blank" href="https://upmextb2cassetsqa.blob.core.windows.net/watuserguides/Web Admin Tool - pikaohje.pdf">{t('help.watShortManual') + ' (FI)'}</a>
                        {/*<a className="help-section-link" href={process.env.PUBLIC_URL + '/UPM Forest Web Admin Tool - short manual (EN).pptx'} >Web Admin Tool - short manual (FI)</a>*/}
                    </div>
                    <div className="help-section-docs-shadowed" style={{ marginLeft: '30px' }}>
                        <p className="help-section-sentence" style={{ marginBottom: '10px' }}>{t('help.detailedUserGuides')}</p>
                        <a className="help-section-link" target="_blank" href="https://upmextb2cassetsqa.blob.core.windows.net/watuserguides/Web Admin Tool - full manual.pdf" >{t('help.watFullManual') + ' (EN)'}</a><br></br>
                        <a className="help-section-link" target="_blank" href="https://upmextb2cassetsqa.blob.core.windows.net/watuserguides/Web Admin Tool - käyttöohje.pdf">{t('help.watFullManual') + ' (FI)'}</a>
                    </div>
                    <div className="help-section-docs" style={{ marginLeft: '30px' }}>
                        <p className="help-section-sentence" style={{ marginBottom: '10px' }}>{t('help.signUpSignInGuides')}</p>
                        <a className="help-section-link" target="_blank" href="https://upmextb2cassetsqa.blob.core.windows.net/watuserguides/User Sign up - Sign in - user guide.pdf" >{t('help.userSignUpSignInManual') + ' (EN)'}</a><br></br>
                        <a className="help-section-link" target="_blank" href="https://upmextb2cassetsqa.blob.core.windows.net/watuserguides/User Sign up - Sign in - ohje.pdf" >{t('help.userSignUpSignInManual') + ' (FI)'}</a>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default Help;