import React,{ useEffect } from 'react';
import '../styles/components/Footer.css';
import { useTranslation } from "react-i18next";


const Footer = (props) => {

    const [t, i18n] = useTranslation('common');
    
    useEffect(() => {
        const savedLanguage = localStorage.getItem("selectedLanguage") || "fi"; // Default to English
        // ✅ Set [lang=xx] on <html> tag
        document.documentElement.setAttribute("lang", savedLanguage);
    }, []);

    return (
        <div className="footer-container fixed-footer">
            <div className="upm-footer">
                <div className="upm-footer__row">
                    <div className="upm-footer__bottom-wrap">
                        <div className="upm-footer__bottom-list-container">
                            <ul>
                                <li>{t('footer.copyright')}&nbsp;© 2021 {t('footer.rightsReserved')}</li>
                                <li><a href="https://www.upm.com/legal-notice/" rel="noopener">{t('footer.legalNotice')}</a></li>
                                <li><a href="https://privacy.upm.com/" target="_blank" rel="noreferrer">{t('footer.privacyPolicy')}</a></li>
                            </ul>
                            <a data-v-63ff81fc="" href="https://privacy.upm.com/" className="cookie-policy-toggle">{t('footer.cookiePolicySettings')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Footer;