import { React, useState } from 'react';
import { useMsal } from "../msal-context";
import { loginRequest } from "../Configs/auth-config";
import '../styles/pages/Main.css';
import '../styles/pages/Home.css';
import { Link, Redirect } from 'react-router-dom';
import Spinner from './Spinner.js'
import { isMobileAccess } from '../helpers/authorizationHelper'
import upmForest from "../images/upm_forest-wide.jpg"
import { useTranslation } from "react-i18next";


const Login = (props) => {

    const { isAuthenticated, loading, login } = useMsal();
    const [userData, setuserData] = useState(null);
    const [t, i18n] = useTranslation('common');

    if (isAuthenticated && sessionStorage.getItem('authenticated')) {
        sessionStorage.setItem('loginTriggered', '')
        return <Redirect to={'/home'} />
    }

    return (
        <div>
            <div className="an-logo-header an-logo-header--beyond-fossils" style={{ width: "100%" }}>
                <div className="an-logo-header__container">
                    <div className="an-logo-header__row">
                        <div className="an-logo-header__title an-logo-header__title--no-company">
                            <span className="an-logo-header__title-companyname">UPM Forest</span>
                            <span className="an-logo-header__title-site-name"> Web Admin Tool</span>
                        </div>
                        <div className="an-logo-header__center"></div>
                        <div className="an-logo-header__logo">
                            <div className="an-logo-header__logo-wrapper">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-main">
                {(loading) &&
                    <div className="container-sub home_page_section content-centered" style={{ display: "inline" }}>
                        <Spinner text={t('loaders.loginInProgress')} />
                    </div>
                }
                {!isAuthenticated &&
                    <div className="hero-wrap">
                        <div className="hero hero--on-dark hero--scale">
                            <img alt="Hoida mets&amp;#228;&amp;#228;si" src={upmForest} className="hero__content-image" />
                            <div className="hero__content-wrapper">
                                <div className="hero__content-text">
                                    <h1 className="hero__header">
                                        {t('login.pleaseLogIn')}
                                    </h1>
                                    <div className="hero__link-container">
                                        {/*<a href="" onClick={() => login(loginRequest, isMobileAccess() ? "loginRedirect" : "loginPopup")} className="btn btn--arrow-right btn--login btn--on-dark-hero">{t('header.logIn')}</a>*/}
                                        <Link onClick={() => login(loginRequest, isMobileAccess() ? "loginRedirect" : "loginPopup")} className="btn btn--arrow-right btn--login btn--on-dark-hero" to="/">{t('header.logIn')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default Login;